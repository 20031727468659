import React from 'react';
import Image from "next/image";

function normalizeSrc(src) {
  return src[0] === '/' ? src.slice(1) : src
}

const customLoader = ({
  src, width, height, quality
}) => {
  let params = ['f_auto', 'dpr_2', 'c_limit', 'q_' + (quality || 'auto')]
  if (height) {
    params.push(`h_${height}`)
  }
  if (width) {
    params.push(`w_${width}`)
  }
  const paramsString = params.join(',')
  return `https://res.cloudinary.com/fluicity/image/fetch/${paramsString}/${process.env.BASE_URL}/${normalizeSrc(src)}`.replace(/\s/g, '');
}

/* eslint-disable */
const CloudinaryImage = (props) => {
  return (
    <Image
      loader={process.env.NODE_ENV === "development" ? null : customLoader}
      {...props}
    />
  );
};
/* eslint-enable */

export default CloudinaryImage;