import { useTranslation } from "next-i18next";
import { NextSeo } from "next-seo";
import { addLocale } from "next/dist/shared/lib/router/utils/add-locale";
import { useRouter } from "next/router";
import React from "react";
import clip from "text-clipper";

import homeBanner from "../../../public/static/images/home/Home-Banner.webp";

const buildUrl = (pathname, locale, defaultLocale) =>
  "https://get.flui.city" + addLocale(pathname, locale, defaultLocale);

const MetaTags = ({ scope = "common", overrides = {}, ...rest }) => {
  const { t } = useTranslation(scope);
  const { locale, locales, asPath, defaultLocale } = useRouter();

  const currentURL = buildUrl(asPath, locale, defaultLocale);

  // NOTE: Google no longer requires the canonical URL to be listed in the hreflang tags.
  // >> Update: to simplify implementation, we no longer recommend using rel="canonical".
  // https://developers.google.com/search/blog/2011/12/new-markup-for-multilingual-content

  // Each language version must list itself as well as all other language versions.
  // https://developers.google.com/search/docs/specialty/international/localized-versions?hl=en&ref_topic=2370587
  let languageAlternates = locales.map((lang) => {
    return {
      hrefLang: lang,
      href: buildUrl(asPath, lang, defaultLocale),
    };
  });

  // the FR version is also available in /fr/pathname
  // consider the /fr as the default version of French poeple
  languageAlternates.push({
    hrefLang: "fr-fr",
    href: "https://get.flui.city/fr" + asPath,
  });

  let title = overrides.title || t("page.title");
  const description = clip(
    overrides.description || t("page.description"),
    140,
    { html: true }
  );
  const image = "https://get.flui.city" + (overrides.image || homeBanner.src);

  title = `${title} - Fluicity`;

  return (
    <NextSeo
      title={title}
      description={description}
      openGraph={{
        url: currentURL,
        title,
        description,
        type: "website",
        images: [
          {
            url: image,
            width: 586,
            height: 690,
            alt: "Fluicity banner",
            type: "image/webp",
          },
        ],
        site_name: "@fluicity",
      }}
      languageAlternates={languageAlternates}
      {...rest} // ex: noIndex
    />
  );
};

export default MetaTags;
