import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";

import CloudinaryImage from "../../Common/CloudinaryImage";

const TestimonialCard = ({ fullName, content, image, job }) => {
  return (
    <Box
      paddingX={{ xs: 1, md: 2, lg: 3 }}
      paddingBottom={{ xs: 1, md: 2, lg: 3 }}
    >
      <Box component={Card} boxShadow={{ xs: 1, sm: 3 }} borderRadius={5}>
        <Box component={CardContent} padding={3}>
          <Typography
            variant={"h6"}
            component="p"
            fontWeight={400}
            color="textSecondary"
          >
            {content}
          </Typography>
          <ListItem
            component={Box}
            width={1}
            disableGutters
            display="flex"
            gap={2}
          >
            <ListItemAvatar>
              <Avatar sx={{ height: 50, width: 50 }}>
                {image ? (
                  <CloudinaryImage
                    component={Avatar}
                    src={image}
                    alt={fullName}
                    height={50}
                    width={50}
                    placeholder="blur"
                    layout="fixed"
                  />
                ) : (
                  fullName.split(" ").map((word) => word[0])
                )}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={fullName} secondary={job} />
          </ListItem>
        </Box>
      </Box>
    </Box>
  );
};

export default TestimonialCard;
